import React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

const Footer = () => {
  return (
    <footer
      style={{
        backgroundColor: "#fff",
        padding: "16px",
        textAlign: "center",
        color: "#001",
      }}
    >
      <Typography variant="body2" color="inherit">
        Bei Problemen oder Feedback bitte an{" "}
        <a href="mailto:parkhaus-oberkochen.de@zeiss.com">
          parkhaus-oberkochen.de@zeiss.com
        </a>{" "}
        wenden
        <br></br>
        <br></br>
        &copy; {new Date().getFullYear()} Carl ZEISS SMT GmbH. All rights
        reserved. <br></br>
        <Link
          href="https://www.zeiss.de/corporate/rechtliches/impressum.html"
          color="inherit"
        >
          Impressum
        </Link>
      </Typography>
      <br></br>
      <Typography variant="caption" color="inherit">
        Großen Dank an Maria Lazaridou für die Unterstützung!
      </Typography> 
    </footer>
  );
};

export default Footer;
