import * as React from "react";
import { useState, useEffect } from "react";
import Title from "../Title";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

export default function StammwerkChart({ data, titel }) {

  const startHour = 6; // Starting hour (inclusive)
  const endHour = 17; // Ending hour (inclusive)

  const getRoundedValues = (data, startHour, endHour) => {
    const newTimeData = [];
    const newAmountData = [];
    const seenIntervals = new Set(); // To track seen intervals

    data.recordset.forEach((record) => {
      const time = record.Timestamp;
      const amount = record.PH_Allg_belegt; // Assuming there's an amount field in your data

      const date = new Date(time);
      const hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();

      // Round to the nearest 0, 15, 30, or 45 minutes
      const remainder = minutes % 15;
      const roundedMinutes = remainder < 7.5 ? minutes - remainder : minutes + (15 - remainder);

      if (roundedMinutes === 60) {
        date.setUTCHours(hours + 1);
        date.setUTCMinutes(0);
      } else {
        date.setUTCMinutes(roundedMinutes);
      }

      // Check if the hour is within the specified range
      if (hours >= startHour -1  && hours <= endHour) {
        // Create a unique key for the interval
        const intervalKey = `${date.getUTCHours()}:${String(date.getUTCMinutes()).padStart(2, '0')}`;

        // Only add if this interval hasn't been seen yet
        if (!seenIntervals.has(intervalKey)) {
          seenIntervals.add(intervalKey);
          const roundedTime = date.toISOString(); // Convert back to ISO string if needed
          

          newTimeData.push(roundedTime);
          newAmountData.push(amount);
        }
      }
    });

    return { newTimeData, newAmountData };
  };

  const [auslastung, setAuslastung] = useState([]);

  Chart.register(...registerables);

  const { newTimeData, newAmountData } = getRoundedValues(data, startHour, endHour);



  useEffect(() => {
    if (data) {
      for (let index = 0; index < data.recordset.length; index++) {
        setAuslastung((x) => [...x, (data.recordset[index].PH_Allg_belegt + data.recordset[index].PH_Allg_frei)]);
      }
    }
  }, [data]);


  const labels = [];


  for (let hour = startHour ; hour <= endHour; hour++) {
    labels.push(`${hour}:00`);
    labels.push(`${hour}:15`);
    labels.push(`${hour}:30`);
    labels.push(`${hour}:45`);
  }


  const chartData = {
    labels,
    datasets: [
      {
        label: "Verwendete Parkplätze",
        lineTension: 0.3,
        data: newAmountData,
        borderColor: "rgb(255, 99, 132)",
        borderWidth: 3,
        backgroundColor: "rgba(255, 99, 132, 1)",
        pointRadius: 2,
      },
      {
        data: auslastung,
        label: "Maximale Auslastung",
        borderColor: "rgba(0, 0, 0, 0.6)",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        pointRadius: 0.2,
      }
    ],
  };

  const options = {
    responsive: true,
    bezierCurve: true,
    maintainAspectRatio: true,
    plugins: {
      title: {
        display: false,
        text: "Chart Title",
        responsive: true,
        font: {
          size: 16,
        },
      },
      legend: {
        display: true,
        position: "top",
        align: "center",
        labels: {
          boxWidth: 20,
          fontSize: 12,
        },
      },
    },
    scales: {
      y: {
        max: auslastung[0] + 50,
        min: 0,
      },
      x: {
        ticks: {
          display: true,
        },
      },
    },
  };
  if (newTimeData) {
    return (
      <React.Fragment>
        <Title>Südwerk-Parkhausauslastung</Title>
        <a>{titel}</a>
        <Line options={options} data={chartData} />
      </React.Fragment>
    );
  } else {
    return <p>Loading...</p>;
  }
}