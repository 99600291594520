import React from "react";
import {
  AppBar,
  Typography,
  Toolbar
} from "@mui/material";
import logo from "../zeiss-logo-tagline_rgb.png";


function Header() {
  // State-Hook für die Ausrichtung

//   // Funktion zum Ändern der Ausrichtung
//   const handleChange = (event, newAlignment) => {
//     setAlignment(newAlignment);
//   };
  return (
    <AppBar position="absolute">
      <Toolbar>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          Parkhaus-Dashboard
        </Typography>

          {/* Zeiss Logo */}
          <div style={{ marginLeft: '16px' }}>
            <img src={logo} height={70} alt="logo" />
          </div>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
