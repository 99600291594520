import * as React from "react";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Charts from "./Chart";
import Deposits from "../Deposits";
import Forcast from "../Forecast";
import Umsteigen from "../umsteigen";
import Footer from "../Footer";
import Faq from "../FAQ";
import HolidayMessage from "../HolidayAlert";


function DashboardContent() {
  const [data, setData] = useState(null);
  const [prediction, setPrediction] = useState(null)
  const [cast, setCast] = useState(null)
  const [difference, setDifference] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetch("https://smt-parkhaus.zeiss.com/api/daily");
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error(error);
    }
  }
  const fetchPrediction = async () => {
    const response = await fetch("https://parkhausapipy.azurewebsites.net/time")
    const data = await response.json()
    console.log(data)
    if (data.time !== null) {
      setPrediction(data.time)
    }
  }
  const fetchCast = async () => {
    const response = await fetch("https://parkhausapipy.azurewebsites.net/cast")
    const data = await response.json()
    const jsonData =  JSON.parse(data)
    setCast(jsonData)
  }

  useEffect(() => {
    fetchPrediction();
  }, []);

  useEffect(() => {

    // Fetch your data immediately after the component is mounted
    fetchData();
    fetchPrediction();
    fetchCast();
  }, []);

  useEffect(() => {
    // Calculate the difference between Akt and Max values
    if (data) {
      const rowsAffected = parseInt(data.rowsAffected[0]) - 1;

      const akt = parseInt(data.recordset[rowsAffected].Akt);
      const max = parseInt(data.recordset[rowsAffected].Max);

      console.log(max)
      console.log(akt)
    

      const time = data.recordset[rowsAffected].Uhrzeit;

      const timeSplit = time.split(":");
      const hours = parseInt(timeSplit[0], 10);

      if (hours >= 12) {
        setDifference(Math.max(0, max - akt));
      } else {
        setDifference(Math.max(0, (max - 446) - akt));
      }
    }
  }, [data]);


  let startTime = null
  let endTime = null

  if (prediction !== null) {
    let receivedTime = prediction
    let timeParts = receivedTime.split(":");
    let timeObject = new Date();
    timeObject.setHours(timeParts[0]);
    timeObject.setMinutes(timeParts[1]);

    let startTimeObject = new Date(timeObject.getTime() - 10 * 60000);
    let endTimeObject = new Date(timeObject.getTime() + 5 * 60000);

    startTime = startTimeObject.getHours().toString().padStart(2, "0") + ":" + startTimeObject.getMinutes().toString().padStart(2, "0");
    endTime = endTimeObject.getHours().toString().padStart(2, "0") + ":" + endTimeObject.getMinutes().toString().padStart(2, "0");
    // console.log(data);
  }
  if (data != null) {
    return (
      <div>
      <Grid container spacing={3}>
        {/* Chart */}
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            {/* <HolidayMessage />  */}
            <Charts data={data} cast={cast} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              mb: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Deposits
              available={difference}
              datum={
                "Stand: " +
                data.recordset[data.rowsAffected[0] - 1].Uhrzeit
              }
              header={"Verfügbare Parkplätze"}
              Auslastung={difference}
            />
          </Paper>
          <Paper
            sx={{
              p: 2,
              mb: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Deposits
              available={(prediction !== null) ? (`${startTime} - ${endTime}`) : ("-")}
              header={"Parkhaus voraussichtlich voll zwischen"}
            />
          </Paper>
          <Paper
            sx={{
              p: 2,
              mb: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Deposits
              available={Math.max(
                0,
                446 -
                (parseInt(data.recordset[data.rowsAffected[0] - 1].E10) + parseInt(data.recordset[data.rowsAffected[0] - 1].E3a + parseInt(data.recordset[data.rowsAffected[0] - 1].E3b)))
              )}
              header={"Verfügbare Schichtparkplätze"}
              datum={"Auf Ebene 10"}
            />
          </Paper>
        </Grid>
        <Umsteigen />
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            sx={{
              p: 2,
              mb: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Faq />
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            sx={{
              p: 2,
              mb: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Forcast />
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            sx={{
              p: 2,
              mb: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Footer />
          </Paper>
        </Grid>
      </Grid>
      </div>
    );
  } else {
    return <p>Loading...</p>;
  }
}

export default function Südwerk() {
  return <DashboardContent />;
}
