import * as React from "react";
import { useState, useEffect } from "react";
import Title from "./Title";
import { Line } from "react-chartjs-2";

export default function Forcast() {
  const [data, setData] = useState(null);
  const [mondayAvg, setMondayAvg] = useState([]);
  const [tuesdayAvg, setTuesdayAvg] = useState([]);
  const [wendesdayAvg, setWendesdayAvg] = useState([]);
  const [thursdayAvg, setThursdayAvg] = useState([]);
  const [fridayAvg, setFridayAvg] = useState([]);
  const [auslastung, setAuslastung] = useState([]);
  const [auslastung2, setAuslastung2] = useState([]);

  useEffect(() => {
    // Fetch data from API and store in state
    fetch("https://smt-parkhaus.zeiss.com/api/forecast")
      .then((res) => res.json())
      .then((data) => setData(data))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (data) {
      for (let i = 0; i < data.recordset.length; i++) {
        if (i >= 5 && i <= 21) {
          setMondayAvg((x) => [...x, data.recordset[i].RollingAverageMonday]);
          setTuesdayAvg((x) => [...x, data.recordset[i].RollingAverageTuesday]);
          setWendesdayAvg((x) => [
            ...x,
            data.recordset[i].RollingAverageWednesday,
          ]);
          setThursdayAvg((x) => [
            ...x,
            data.recordset[i].RollingAverageThursday,
          ]);
          setFridayAvg((x) => [...x, data.recordset[i].RollingAverageFriday]);
        }
        if (i >= 7) {
          setAuslastung2((x) => [...x, 3302]);
          setAuslastung((x) => [...x, null]);
        } else {
          setAuslastung((x) => [...x, 2668]);
          setAuslastung2((x) => [...x, null]);
        }
      }
    }
  }, [data]);

  const labels = [
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
  ];

  const chartData = {
    labels,
    datasets: [
      {
        label: "Vorhersage Montag",
        lineTension: 0.3,
        data: mondayAvg,
        borderColor: "rgba(0, 99, 132, 0.7)",
        borderWidth: 3,
        backgroundColor: "rgba(0, 99, 132, 1)",
      },
      {
        label: "Vorhersage Dienstag",
        lineTension: 0.3,
        data: tuesdayAvg,
        borderColor: "rgba(100, 99, 132, 0.7)",
        borderWidth: 3,
        backgroundColor: "rgba(100, 99, 132, 1)",
      },
      {
        label: "Vorhersage Mittwoch",
        lineTension: 0.3,
        data: wendesdayAvg,
        borderColor: "rgba(255, 5, 132, 0.7)",
        borderWidth: 3,
        backgroundColor: "rgba(255, 5, 132, 1)",
      },
      {
        label: "Vorhersage Donnerstage",
        lineTension: 0.3,
        data: thursdayAvg,
        borderColor: "rgba(50, 99, 50, 0.7)",
        borderWidth: 3,
        backgroundColor: "rgba(50, 99, 50, 1)",
      },
      {
        label: "Vorhersage Freitag",
        lineTension: 0.3,
        data: fridayAvg,
        borderColor: "rgba(255, 99, 7, 0.7)",
        borderWidth: 3,
        backgroundColor: "rgba(255, 99, 7, 1)",
      },
      {
        data: auslastung,
        label: "Maximale Auslastung",
        borderColor: "rgba(0, 0, 0, 0.6)",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
      },
      {
        data: auslastung2,
        borderColor: "rgba(0, 0, 0, 0.6)",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
      },
    ],
  };

  const options = {
    responsive: true,
    bezierCurve: true,
    maintainAspectRatio: true,
    plugins: {
      title: {
        display: false,
        text: "Chart Title",
        responsive: true,
        font: {
          size: 16,
        },
      },
      legend: {
        display: true,
        position: "top",
        align: "center",
        labels: {
          filter: function (legendItem, chartData) {
            return legendItem.datasetIndex !== 6 && legendItem.datasetIndex !== 5; // Show only the legend for the first dataset
          },
          boxWidth: 20,
          fontSize: 10,
        },
      },
    },
    scales: {
      y: {
        max: 3500,
        min: 0,
      },
      x: {
        ticks: {
          display: true,
        },
      },
    },
  };
  if (data) {
    if (window.innerWidth > 580) {
      return (
        <React.Fragment>
          <Title>Wochenvorschau</Title>
          <a>Auf Legende drücken um Tage auszublenden</a>
          <Line options={options} data={chartData} />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Title>Wochenvorschau</Title>
          <a>Zur besseren Darstellung Smartphone drehen</a>
          <Line options={options} data={chartData} />
        </React.Fragment>
      );
    }
  } else {
    return <p>Loading...</p>;
  }
}
