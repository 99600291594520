import React, { useState } from "react";
import Südwerk from "./SMT/Südwerk";
import Stammwerk from "./AG/Stammwerk";
import Ausenparkplatz from "./OUT/Ausenparkplatz";
import Header from "./Header";
import Navigation from "./Navigation";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";


const Homepage = () => {

  const [buttonValue, setButtonValue] = useState("SMT")
  const mdTheme = createTheme();

  const handleDataFromChild = (data) => {
    setButtonValue(data);
  }

  console.log(buttonValue)

  return (
    <div>
      <Header />
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Navigation buttonValue={handleDataFromChild}/>
                {buttonValue === 'SMT' ? (
                  <Südwerk />
                ) : (buttonValue === "Stammwerk" ? (<Stammwerk />) : (<Ausenparkplatz />))}
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </div>
  );
};

export default Homepage;