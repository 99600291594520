import { Paper, Grid, Button } from "@mui/material";
import Title from "./Title";
import React, { useState } from "react";

const Navigation = ({buttonValue}) => {
  const [alignment, setAlignment] = useState('SMT');

  const handleButtonClick = (value) => {
    buttonValue(value);
    setAlignment(value)
  };


  return (
    <Grid container spacing={2} >
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            mb: 2,
            display: "flex",
            flexDirection: "column",
            maxWidth: "100%",
          }}
        >
          <Title>#Navigation</Title>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={4}>
              <Button
                fullWidth
                variant={alignment === "SMT" ? "contained" : "outlined"}
                onClick={() => handleButtonClick("SMT")}
                sx={{ height: 50 }}
              >
                Süd
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                fullWidth
                variant={alignment === "Stammwerk" ? "contained" : "outlined"}
                onClick={() => handleButtonClick("Stammwerk")}
                sx={{ height: 50 }}
              >
                Stamm
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                fullWidth
                variant={alignment === "Außenparkplätze" ? "contained" : "outlined"}
                onClick={() => handleButtonClick("Außenparkplätze")}
                sx={{ height: 50 }}
              >
                Aussen
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Navigation;
