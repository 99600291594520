import * as React from "react";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Umsteigen from "../umsteigen";
import Footer from "../Footer";
import Faq from "../FAQ";
import Ausenchart from "./Ausenchart";


function DashboardContent() {
  const [p12, setP12] = useState(null);
  const [p28, setP28] = useState(null);
  const [pHeuchelbach, setPHeuchelbach] = useState(null);


  const fetchData = async () => {
    try {
      //P12
      const res_p12 = await fetch("https://smt-parkhaus.zeiss.com/api/p12");
      const data_p12 = await res_p12.json();
      setP12(data_p12);
      //P28
      const res_p28 = await fetch("https://smt-parkhaus.zeiss.com/api/p28");
      const data_p28 = await res_p28.json();
      setP28(data_p28);
      // Heuchelbach
      const res_pheuchelbach = await fetch("https://smt-parkhaus.zeiss.com/api/heuerbach");
      const data_pheuchelbach = await res_pheuchelbach.json();
      setPHeuchelbach(data_pheuchelbach);

    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {

    // Fetch your data immediately after the component is mounted
    fetchData();

  }, []);

  // }
  if (p12 && p28 && pHeuchelbach != null) {
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Ausenchart data={p12} titel={"P12 Bahnlinie Stammwerk"} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Ausenchart data={p28} titel={"P28 OWEMA"} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Ausenchart data={pHeuchelbach} titel={"Parkplatz Heuchelbach"} />
            </Paper>
          </Grid>
          <Umsteigen />
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 2,
                mb: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Faq />
            </Paper>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 2,
                mb: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Footer />
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return <p>Loading...</p>;
  }
}

export default function Ausenparkplatz() {
  return <DashboardContent />;
}
