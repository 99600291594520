import * as React from "react";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Umsteigen from "../umsteigen";
import Footer from "../Footer";
import Faq from "../FAQ";
import StammwerkChart from "./StammwerkChart";
import Deposits from "../Deposits";


function DashboardContent() {
  const [stammwerk, setStammwerk] = useState(null);


  const fetchData = async () => {
    try {
      //P12
      const res = await fetch("https://smt-parkhaus.zeiss.com/api/stammwerk");
      const data = await res.json();
      setStammwerk(data);

    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {

    // Fetch your data immediately after the component is mounted
    fetchData();

  }, []);

  // }
  if (stammwerk != null) {
    var length = stammwerk.recordset.length - 1
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} md={9} lg={9}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <StammwerkChart data={stammwerk} titel={"Auf Legende drücken um Linien auszublenden"} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              mb: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Deposits
              available={stammwerk.recordset[length].PH_Allg_frei}
              datum={" "}
              header={"Verfügbare Parkplätze"}
              Auslastung={stammwerk.recordset[length].PH_Allg_frei}
            />
          </Paper>
        </Grid>
          <Umsteigen />
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 2,
                mb: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Faq />
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 2,
                mb: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Footer />
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return <p>Bislang kein Fahrzeug eingefahren...</p>;
  }
}

export default function Stammwerk() {
  return <DashboardContent />;
}
